import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { Container } from 'react-bootstrap';
import { getUser, removeUserStats, setUserSession } from '../../../functions/manageSessions';

import { H1, Span, Label } from '../../theme/typography';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import { AppContext } from '../../../context';
import SignUpStepTwo from './step-two-signup/index';
import SignUpStepOneSoarBeyond from './step-one-signup-soar-beyond';
import {
  SignUpFormTitleWrapper,
  Breadcrumb,
} from '../styles';


const SignUpFormSoarBeyond = (props) => {
  const [step, setStep] = useState(1);
  const [signUpEmailError, setSignUpEmailError] = useState(undefined);
  const [loginCodeError, setLoginCodeError] = useState(undefined);
  const [inputEmail,setInputEmail] =useState("");
  const [inputsSoarBeyond,setInputsSoarBeyond] = useState({});
  const [input,setInput] =useState({});
  const {setRequestTrackerContext ,setUserContext,removeUserOrganisationContext} = useContext(AppContext);
  const [notLoading, setNotLoading] = useState(true);
  const [organisation, setOrganisation] = useState(undefined);
  const [consent, setConsent] = useState(false);
  const [consent2, setConsent2] = useState(false);

  const history = useHistory();

  const ORGNUM = "884";

  const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
       return (
        (promiseInProgress ) &&
        <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
       }}
       >
        <Loader type="Oval" color="#8771DF" height="100" width="100" />
    </div>
      );
     }

  // this method send the post request to login with code
  const login_user = (data,endpoint) => {
    //
    let data_to_send = JSON.stringify({
      "email": data.inputEmail,
      "code": data.code
    });
    setNotLoading(false);
    setRequestTrackerContext(prev => { return {...prev, endpoint:true}});
    let endpoint_url = window.BASE_URL_USER + endpoint;
    trackPromise(
      axios({
        method: 'post',
        url: endpoint_url,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data_to_send,
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, endpoint:false}});
          setNotLoading(true);
          if (res.data.success) {
            removeUserOrganisationContext();
            if (!res.data.user.is_anonymous){
              setLoginCodeError(undefined);
              removeUserStats();
              //TO DO also get filters
              // setUserSession(res.data.user);
              setUserContext(res.data.user);
              modify_user(data.lastName, data.firstName, data.pharmacy, data.interest, data.telephone_number, data.country, data.county, 
                data.pratice_organisation, data.practice_postcode, data.alternative_email, data.current_role, data.time_in_practice, data.senior_or_clinical, 
                data.it_system);
    //           tempInterest, tempTelephone, tempCountry, tempCounty, tempPractice, tempPracticePC, tempGPNumber, 
    // tempAlterEmail, tempRole, tempTime, tempIfSenior, tempITSystem
            }
            else {
              setStep(2);
              if(res.data && Object.hasOwn(res.data,'msg')){
                setLoginCodeError(res.data.msg);
              }
              else{
                setLoginCodeError("Wrong code");
              }
            }
          }
          else {
            setStep(2);
            if(res.data && Object.hasOwn(res.data,'msg')){
                setLoginCodeError(res.data.msg);
              }
              else{
                setLoginCodeError("Wrong code");
              }
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, endpoint:false}});
          setNotLoading(true);
          setStep(2);
          console.log('Error: ', error);
        })
    );
  };

  // This  method send the post request that registers the user
  const registers_user_send_email = (data) => {

    var data_to_send = {
      email:data.email,
      org_num: 43,
    };
    setRequestTrackerContext(prev => { return {...prev, 'subscribe-user':true}});
    setNotLoading(false);
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}subscribe-user`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data_to_send),
      })
        .then((res) => {
          setConsent(true);
          setConsent2(true);
          setInputEmail(data.email);
          if (res.data.success) {
            setNotLoading(true);
            // let email = data.email;
            // props.setUserEmail(email);
            setSignUpEmailError(undefined);
            setInputsSoarBeyond(data)
            setInputEmail(data.email);
            setInput(data);    
            setStep(2);
          }
          else {
            if (res.data.redirect) {
              window.location.replace(res.data.redirect_url + "&username=" + data.email);
            } else {
              setNotLoading(true);
              
              if (res.data.msg){
                // props.setUserEmail(data.email);
                if ( res.data.msg ==='Email already exists' ){
                  setInputEmail(data.email);
                  setInputsSoarBeyond(data);
                  setSignUpEmailError(res.data.msg);
                }
                else{
                  setSignUpEmailError(false);
                }
                
                if ( !res.data.info['must-be-linked'] ){
                  setSignUpEmailError(res.data.msg);
                }
              }
              else {
                if(res.data.description){
                  setSignUpEmailError("Server error");
                }
              }
            }
          }
          setRequestTrackerContext(prev => { return {...prev, 'subscribe-user':false}});
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'subscribe-user':false}});
          setNotLoading(true);
          setInputsSoarBeyond(data);
          setStep(1);
          console.log('Error: ', error);
        })
    );
  };

  // / modify_user(data.lastName, data.firstName, data.pharmacy, data.interest, data.telephone_number, data.country, data.county, 
  //   data.pratice_organisation, data.practice_postcode, data.alternative_email, data.current_role, data.time_in_practice, data.senior_or_clinical, 
  //   data.it_system);

  // submit for name and profession
  const modify_user = (tempLastName, tempFirstName, tempGPNumber, tempInterest, tempTelephone, tempCountry, tempCounty, tempPractice, tempPracticePC, 
    tempAlterEmail, tempRole, tempTime, tempIfSenior, tempITSystem) => {
    setNotLoading(false);
    setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});

    let dataToSend  = {
      "first-names": tempFirstName,
      "last-names": tempLastName,
      "therapy_interest": tempInterest,
      "telephone_num": tempTelephone,
      "country": tempCountry,
      "county": tempCounty,
      "practice": tempPractice,
      "practice_postcode": tempPracticePC,
      "gphc_psni_num": tempGPNumber};
    if (tempAlterEmail && tempAlterEmail.trim() !== '') {
      dataToSend  = {...dataToSend,
        "alternative_email": tempAlterEmail};
    }
    if (tempRole && tempRole.trim() !== '') {
      dataToSend  = {...dataToSend,
        "current_role": tempRole};
    }
    if (tempTime && tempTime.trim() !== '') {
      dataToSend  = {...dataToSend,
        "time_in_practice": tempTime};
    }
    if (tempIfSenior && tempIfSenior.trim() !== '') {
      dataToSend  = {...dataToSend,
        "senior_or_clinical": tempIfSenior};
    }
    if (tempITSystem && tempITSystem.trim() !== '') {
      dataToSend  = {...dataToSend,
        "it_system": tempITSystem};
    }

    // console.log("datatosend", dataToSend);
    trackPromise(
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}modify-user-fields`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(dataToSend),
      })
        .then((res) => {
          setNotLoading(true);
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          if (res.data.num_modifications) {
            //success with email address
            setSignUpEmailError(undefined);
            var user_info = getUser();
            // setUserSession({
            //   ... user_info,
            //  "first-names": tempFirstName,
            //  "last-names": tempLastName,
            // });
            setUserContext({
              ... user_info,
             "first-names": tempFirstName,
             "last-names": tempLastName,
            });
            history.push('/');

          } 
          else {
            console.log(
              'The request is successful, but for some reason res.data.success is not received.'
            );
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          setSignUpEmailError(undefined);
          setNotLoading(true);
          console.log('Error: ', error);
          setStep(3);
        })
    );
  };

  const step2CallBack = (code) =>{
    const stepTwoValues = {};
    stepTwoValues['code'] = code;
    stepTwoValues['inputEmail'] = inputEmail;
    stepTwoValues['code'] = stepTwoValues['code'].replaceAll(' ', '');
    let valuesTemp= {...stepTwoValues, ...input};
    login_user(valuesTemp,'login-user');
  };

  const step1CallBack = (values) => {
    const stepOneValues = values;
    // stepOneValues['email'] = email;
    // stepOneValues['consent'] = consent;
    // stepOneValues['lastName'] = tempLastName;
    // stepOneValues['firstName'] = TempFirstName;
    // stepOneValues['pharmacy'] = pharmacyNumber;
    // stepOneValues['interest'] = interest;
    // stepOneValues['telephone_number'] = telephone_number;
    // stepOneValues['country'] = country;
    // stepOneValues['county'] = county;
    // stepOneValues['pratice_organisation'] = pratice_organisation;
    // stepOneValues['practice_postcode'] = practice_postcode;
    // stepOneValues['alternative_email'] = alternative_email;
    // stepOneValues['it_system'] = it_system; // IT system??
    // stepOneValues['time_in_practice'] = time_in_practice; // undefined
    // stepOneValues['senior_or_clinical'] = senior_or_clinical; // undefined
    // stepOneValues['current_role'] = current_role; // undefined
    registers_user_send_email(stepOneValues);

  };


  return (
    <Container>
      <SignUpFormTitleWrapper>
        <H1 h1 semibold textoffblack>
          { ( (step === 1 || step === 2 ) ) && 'Create your Medwise Soar Beyond account'}
        </H1>
      </SignUpFormTitleWrapper>

      <Breadcrumb p1 semibold>
        <Span className={ ( (step === 1|| step === 2) && props.defaultStep!==3) && 'active'} >
          Step 1
        </Span>
        {'\u00A0\u00A0>\u00A0\u00A0'}
        <Span
          className={ (step === 3 || props.defaultStep===3 ) && 'active'} >
          Step 2
        </Span>
      </Breadcrumb>
      {(step === 1 ) && notLoading  && props.defaultStep!==3 &&(  
        <>
      <SignUpStepOneSoarBeyond 
      loginCallBack={step1CallBack}  
      registerError={signUpEmailError} 
      setStepOneConsent ={setConsent} 
      setStepTwoConsent= {setConsent2}
      stepOneConsent= {consent} 
      stepTwoConsent= {consent2} 
      email={inputEmail} 
      inputData={inputsSoarBeyond}
      />
    </>
      )}
    <LoadingIndicator/>
    {notLoading && step ==2 && props.defaultStep!==3 &&(
        <SignUpStepTwo 
        loginCallBack={step2CallBack} 
        loginError={loginCodeError} 
        email= {inputEmail} />
        )}

    </Container>
  );
};

export default SignUpFormSoarBeyond;
